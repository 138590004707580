import { Button, styled } from "@mui/material";
import Menu from '@mui/material/Menu';
import { saasModernTheme } from 'common/theme/saasModern';
import MenuItem from '@mui/material/MenuItem';
import { color, display } from "styled-system";
import Link from "next/link";


export const SubMenuButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    position: 'relative',
    color: '#ffffff',
    textTransform:'capitalize',
    fontFamily: 'Manrope',
    padding: theme.spacing(2), // Assuming radius[4] corresponds to spacing(4)
    borderRadius: theme.shape.borderRadius, // Assuming radius[1] corresponds to the default border radius
    fontSize: '16px',
    fontWeight:700,
    '&:hover': {
        backgroundColor:  saasModernTheme.colors.bgBlue,
        transform: 'rotate(2deg)',
    },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiMenu-paper': {
        backgroundColor: saasModernTheme.colors.backGroundNavBar,
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        color: theme.palette.common.white,
    },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '& .MuiMenuItem-root': {
        color: theme.palette.common.white,
        fontFamily: 'Manrope',
        fontWeight:'bold',
    },
    padding:0
}));

export const StyledLink = styled(Link)(({ theme }) => ({
    display:'block',
    textDecoration:'none',
    color: theme.palette.common.white,
    fontSize: '16px',
    width: '100%',
    padding: theme.spacing(1), // Assuming radius[4] corresponds to spacing(4)
    '&:hover': {
        backgroundColor: saasModernTheme.colors.bgBlue,
    }
}));