import React from 'react';

const Swiftask = ({ isDark }) => {
  return (
    <svg
      width='129'
      height='30'
      viewBox='0 0 129 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group 172'>
        <g id='Group 171'>
          <g id='Group'>
            <path
              id='Vector'
              d='M60.0363 10.7557L58.8392 13.2417C57.5149 12.4724 55.8543 11.9376 54.8356 11.9376C54.0155 11.9376 53.4603 12.2432 53.4603 12.8494C53.4603 15.0145 60.077 13.7817 60.077 18.3002C60.077 20.8015 57.8612 22.126 55.0699 22.126C52.9763 22.126 50.8319 21.3414 49.3496 20.1239L50.5823 17.6736C51.871 18.7994 53.8015 19.5534 55.1055 19.5534C56.109 19.5534 56.7304 19.1764 56.7304 18.4632C56.7304 16.2472 50.1137 17.5717 50.1137 13.1347C50.1137 10.827 52.1002 9.35989 55.1004 9.35989C56.9036 9.35989 58.7476 9.91516 60.0363 10.7557Z'
              fill={isDark ? 'rgb(40, 42, 54)' : 'white'}
            />
            <path
              id='Vector_2'
              d='M70.849 22.0186L68.9542 15.9055L67.0593 22.0186H63.9827L60.4629 12.3803H63.6108L65.5974 19.0486L67.5279 12.3803H70.4772L72.4637 19.0486L74.4146 12.3803H77.4352L73.9155 22.0186H70.8592H70.849Z'
              fill={isDark ? 'rgb(40, 42, 54)' : 'white'}
            />
            <path
              id='Vector_3'
              d='M81.7244 9.60942C81.7244 10.5773 81.0469 11.2548 80.0995 11.2548C79.1521 11.2548 78.4746 10.5773 78.4746 9.60942C78.4746 8.64153 79.1521 7.98438 80.0995 7.98438C81.0469 7.98438 81.7244 8.6619 81.7244 9.60942ZM78.5408 22.0189V12.3807H81.6174V22.0189H78.5408Z'
              fill={isDark ? 'rgb(40, 42, 54)' : 'white'}
            />
            <path
              id='Vector_4'
              d='M87.7294 12.1105V12.8084H89.9095V14.846H87.7294V22.0136H84.6731V14.846H83.3691V12.8084H84.6731V11.9169C84.6731 9.78751 86.2828 8.61075 88.3559 8.61075C89.3033 8.61075 90.3221 8.86036 91.0556 9.28828L89.9655 11.4533C89.6243 11.2037 89.1964 11.0611 88.8398 11.0611C88.2133 11.0407 87.7294 11.4024 87.7294 12.1156V12.1105Z'
              fill={isDark ? 'rgb(40, 42, 54)' : 'white'}
            />
            <path
              id='Vector_5'
              d='M97.4781 21.4993C96.7802 21.9119 95.8328 22.1616 94.9057 22.1616C93.0465 22.1616 91.7068 21.1071 91.7068 19.105V14.8514H90.3672V12.829H91.7068V10.1494H94.7835V12.8137H97.3049V14.8514H94.7835V18.6262C94.7835 19.375 95.1044 19.7011 95.6749 19.6807C95.9958 19.6807 96.4084 19.5533 96.8719 19.3598L97.4781 21.5044V21.4993Z'
              fill={isDark ? 'rgb(40, 42, 54)' : 'white'}
            />
            <path
              id='Vector_6'
              d='M107.559 15.7223V22.0136H104.538V20.9031C103.912 21.7232 102.893 22.1563 101.589 22.1563C99.5312 22.1563 98.2832 20.9031 98.2832 19.1354C98.2832 17.3677 99.5872 16.2368 101.981 16.2215H104.518V16.0789C104.518 15.1314 103.891 14.5608 102.643 14.5608C101.839 14.5608 100.784 14.8461 99.7655 15.3657L98.8894 13.328C100.392 12.6301 101.752 12.2379 103.357 12.2379C106 12.2379 107.539 13.542 107.559 15.7223ZM104.518 18.7431V17.8873H102.567C101.64 17.8873 101.171 18.2083 101.171 18.9265C101.171 19.6448 101.671 20.0727 102.531 20.0727C103.499 20.0727 104.319 19.5378 104.518 18.7482V18.7431Z'
              fill={isDark ? 'rgb(40, 42, 54)' : 'white'}
            />
            <path
              id='Vector_7'
              d='M117.17 13.3284L116.115 15.3304C114.954 14.7038 113.879 14.3625 113.074 14.3625C112.519 14.3625 112.127 14.5765 112.127 15.0248C112.127 16.3493 117.205 15.7023 117.185 19.0645C117.185 21.0512 115.453 22.1414 113.125 22.1414C111.516 22.1414 109.962 21.6778 108.801 20.7812L109.784 18.8149C110.874 19.5841 112.127 20.012 113.197 20.012C113.803 20.012 114.251 19.798 114.251 19.3497C114.251 17.9387 109.264 18.6722 109.28 15.2897C109.28 13.3029 110.961 12.2332 113.197 12.2332C114.592 12.2332 115.983 12.6254 117.165 13.3233L117.17 13.3284Z'
              fill={isDark ? 'rgb(40, 42, 54)' : 'white'}
            />
            <path
              id='Vector_8'
              d='M122.108 19.3904V22.019H119.016V8.75372H122.108V15.7633L125.291 12.3859H128.796L125.255 16.0894L128.903 22.0241H125.24L123.147 18.3053L122.108 19.3955V19.3904Z'
              fill={isDark ? 'rgb(40, 42, 54)' : 'white'}
            />
          </g>
        </g>
        <path
          id='Vector_9'
          d='M30.295 16.6071H35.0475C36.0561 16.6071 36.9627 16.1995 37.6249 15.5373C38.282 14.8802 38.6946 13.9683 38.6946 12.9647C38.6946 10.9525 37.0646 9.32238 35.0526 9.32238H20.0872C19.6389 9.32238 19.2111 9.05748 19.0786 8.62957C18.8596 7.93167 19.3792 7.2847 20.0464 7.2847H27.3203C28.3238 7.2847 29.2355 6.87717 29.8977 6.21492C30.5599 5.55777 30.9674 4.64591 30.9674 3.64235C30.9674 1.63014 29.3374 0 27.3254 0H8.76889C7.76543 0 6.85365 0.407536 6.19146 1.06978C5.41212 1.83901 4.98934 2.96482 5.16253 4.17724C5.41721 5.98568 7.04721 7.2847 8.87586 7.2847H12.2581C12.7064 7.2847 13.1342 7.5496 13.2667 7.97751C13.4857 8.67542 12.9661 9.32238 12.2989 9.32238H3.64459C2.63603 9.32238 1.72935 9.72992 1.06716 10.3922C0.292912 11.1614 -0.134962 12.2872 0.0382248 13.4996C0.298005 15.3081 1.928 16.6071 3.75665 16.6071H23.1129C23.6732 16.6071 24.1775 17.0299 24.1978 17.5903C24.2182 18.1506 23.7547 18.6499 23.1791 18.6499H16.3229C15.3194 18.6499 14.4077 19.0574 13.7506 19.7196C12.9712 20.494 12.5484 21.6147 12.7216 22.8271C12.9763 24.6355 14.6063 25.9397 16.435 25.9397H24.8244C25.8329 25.9397 26.7956 26.3676 27.468 27.1215L29.7908 29.7246C30.295 30.285 31.2221 29.9335 31.2221 29.1796V27.4221C31.2221 26.607 31.8843 25.9447 32.6993 25.9447H33.0966C34.1001 25.9447 35.0118 25.5372 35.674 24.8801C36.3922 24.1618 36.8048 23.1633 36.7335 22.0579C36.6062 20.1221 34.9202 18.66 32.9794 18.66H30.2288C29.6532 18.66 29.1897 18.1863 29.2101 17.6005C29.2304 17.0146 29.7347 16.6173 30.295 16.6173V16.6071Z'
          fill='#FF6E72'
        />
      </g>
    </svg>
  );
};

export default Swiftask;