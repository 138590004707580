import styled from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';
import Link from 'next/link';

export const NavWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    display: inline-block;
    margin-left: ${saasModernTheme.radius[2]}px;
    text-decoration: none;
    @media (max-width: 950px) {
      margin-left: 0;
    }
  }
`;

export const NavLi = styled.li`
  size: 18px;
  font-weight: 700;
  line-height: 24.59px;
  letter-spacing: 0.01em;
  color: white;
  a {
    text-decoration: none;
    font-family: Manrope;
    background-color: ${(props) => (props.active ? '#1098f7' : 'transparent ')};
    transform: ${(props) => (props.active ? 'rotate(2deg)' : 'rotate(0deg)')};
    position: relative;
    color: #ffffff;
    display: block;
    padding: ${saasModernTheme.radius[4]}px;
    border-radius: ${saasModernTheme.radius[1]}px;
    @media (max-width: 950px) {
      transform: rotate(0deg);
    }
  }
  @media (max-width: 950px) {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

export const ContentLanguage = styled.li`
  padding: ${saasModernTheme.space[4]}px;
`;

export const SubMenu = styled(Link)`
  padding: ${saasModernTheme.space[4]}px;
`;
