'use client'
import * as React from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Container,
} from '@mui/material'
import Swiftask from 'common/assets/image/logo/swiftask';
import BtnGetStarted from 'common/components/Button/BTNGetStarted';
import Link from 'next/link'
import DrawerNavbar from './drawer'
import Colors from 'common/theme/saasModern/colors';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import { MENU_ITEMS } from 'common/data/SaasModern';
import {MenuBurger} from 'common/assets/image/MenuBurger'

export default function NavBar({ locale,promotion }) {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <AppBar
      position="fixed" 
      color="transparent" 
      sx={{
        backgroundColor: Colors.backGroundNavBar,
        padding:(theme)=>theme.spacing(1.5),
        display:mobileOpen ? 'none':'block',
        boxShadow:'none',
      }} 
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          {/* Logo and Navigation - Desktop */}
          <Box sx={{ alignItems: 'center', gap: 2 }}>
            <Link href="/">
              <Swiftask />
            </Link>
          </Box>

          {/* Language & CTA - Desktop */}
          <Box sx={{ display: { xs: 'none', sm:"none", md: 'flex' }, alignItems: 'center', gap: 2 }}>
            <ScrollSpyMenu
                className='main_menu'
                menuItems={MENU_ITEMS}
                offset={-70}
                locale={locale}
              />
            <BtnGetStarted locale={locale} />
          </Box>

          {/* Mobile Menu Icon */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              onClick={handleDrawerToggle}
              color={Colors.white}
            >
              <MenuBurger />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
      <DrawerNavbar toggleHandler={handleDrawerToggle} isOpen={mobileOpen} locale={locale} />
    </AppBar>
  )
}

