import { SubMenuButton, StyledMenu, StyledMenuItem,StyledLink } from './style';
import { arrow_down } from 'react-icons-kit/ikons/arrow_down';
import { arrow_up } from 'react-icons-kit/ikons/arrow_up';
import Icon from 'react-icons-kit';
import React, { useCallback } from 'react';

const SubMenu = ({ label, item = [], locale }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleCompanyHover = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeSubMenu = useCallback(() => {
    setAnchorEl(null)
  }, []);

  return (
    <>
      <SubMenuButton
           color="inherit"
           onMouseEnter={handleCompanyHover}
           endIcon={<Icon icon={Boolean(anchorEl) ? arrow_up : arrow_down} />}
      >
        {label}
      </SubMenuButton>
      <StyledMenu
         anchorEl={anchorEl}
         open={Boolean(anchorEl)}
         onClose={closeSubMenu}
         MenuListProps={{ onMouseLeave: closeSubMenu }}
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'left',
         }}
         keepMounted={true}
      >
        {item.map((subItem, index) => (
          <StyledMenuItem key={index} onClick={closeSubMenu}>
            <StyledLink href={subItem.path}>{subItem.intl[locale]}</StyledLink>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default SubMenu;