import { saasModernTheme } from 'common/theme/saasModern';
import Link from 'next/link';
import {
  ListItemText,
  ListItemButton,
  styled
} from '@mui/material'
import {listItemTextClasses} from "@mui/material/ListItemText"

export const LinkMobile = styled(Link)(({ theme, active }) => ({
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '24.59px',
  letterSpacing: '0.01em',
  color: '#ffffff',
  textDecoration: 'none',
  fontFamily: 'Manrope',
  backgroundColor: active ? '#1098f7' : 'transparent',
  position: 'relative',
  display: 'block',
  padding: `${saasModernTheme.space[3]}px`,
  borderRadius: `${saasModernTheme.radius[1]}px`,
  '@media (max-width: 950px)': {
    transform: 'rotate(0deg)',
    display: 'block',
    width: '100%',
    margin: 0,
  },
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme, active }) => ({
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24.59px',
    letterSpacing: '0.01em',
    color: '#ffffff',
    textDecoration: 'none',
    fontFamily: 'Manrope',
    backgroundColor: active ? '#1098f7' : 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${saasModernTheme.space[3]}px`,
    borderRadius: `${saasModernTheme.radius[1]}px`,
}));
 