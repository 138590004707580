'use client'
import React from 'react';
import Drawer from 'common/components/Drawer';
import BtnGetStarted from 'common/components/Button/BTNGetStarted';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Collapse,
} from '@mui/material'
import Icon from 'react-icons-kit'
import {chevronUp} from 'react-icons-kit/oct/chevronUp'
import {chevronDown} from 'react-icons-kit/oct/chevronDown'
import { MENU_ITEMS } from 'common/data/SaasModern';
import { useRouter } from 'next/router';
import { LinkMobile,StyledListItemButton } from "./style"

const DrawerNavbar = ({ toggleHandler, isOpen, locale }) => {
  const [openCollapse, setoOpenCollapse] = React.useState(false);
  const handleToggle = () => {
    setoOpenCollapse(!openCollapse);
  };

  const router = useRouter();

  const activeTabs = (location) => {
    if (router.pathname.includes('/blog')) {
      return location.includes('/blog');
    }

    return router.pathname === location;
  };

  return (
    <Drawer open={isOpen} toggleDrawer={toggleHandler}>
        <Box p={2}>
        <List>
          {MENU_ITEMS.map((item,index) => (
            <>
            {
              (item?.subMenu || []).length > 0 ?
              <>
                <ListItem disablePadding>
                  <StyledListItemButton
                    onClick={handleToggle}
                  >
                      {item.intl[locale]}
                    <Icon icon={openCollapse ? chevronUp : chevronDown} />
                  </StyledListItemButton>
                </ListItem>
                <Collapse in={openCollapse} timeout="auto" unmountOnExit={true}>
                  <List component="div" disablePadding>
                    { item.subMenu.map((subMenu,index) => (
                      <ListItemButton 
                        key={index} 
                        sx={{ pl: 4 }}
                      >
                        <LinkMobile 
                          href={subMenu.path}
                          active={activeTabs(subMenu.location)}
                        >
                          {subMenu.intl[locale]}
                        </LinkMobile>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse> 
                </> :
                <ListItem key={index} disablePadding={true} >
                  <LinkMobile 
                    href={item.path}
                    active={activeTabs(item.location)}
                  >
                    {item.intl[locale]}
                  </LinkMobile>
                </ListItem>
            }
            </>
          ))}
        </List>
        <BtnGetStarted locale={locale} />
      </Box>
    </Drawer>
  );
};

export default DrawerNavbar;
